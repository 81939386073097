<template>
  <div id="ket-wrap" class="ket-wrap">
    <div class="top">
      <div class="logo-num">
        <img :src="require(`../../assets/ket-paper/${this.group_tag}.png`)" />
      </div>
      <div class="tit-cont">
        <div class="title">{{pageData.title}}</div>
        <div class="part">{{pageData.part}}</div>
      </div>
    </div>
    <div class="center">
      <div class="pngCont" v-if="pageData.imgUrl" :style="`background-image:url(${pageData.imgUrl})`"></div>
      <div class="pngCont" v-else :style="`background-image:url(${demoPng});background-size:cover`"></div>
      <div class="qa">
        <div class="cont" v-if="pageData.roles?.indexOf('Examiner')!=-1&&stateType!='done'">
          <div class="name" style="margin-top: 0;">Examiner</div>
          <div class="audio-cont examiner" style="flex-direction: column;">
						<div class="play-voice" v-if="imgstatus == 'Examiner'">
							<img src="../../assets/ket-paper/play.gif" />
						</div>
          </div>
        </div>
				<div class="cont" v-if="pageData.roles?.indexOf('AI Student')!=-1&&stateType!='done'">
          <div class="name">AI Student</div>
          <div class="audio-cont ai-cont">
            <!-- <img class="student-play" v-if="imgstatus == 'AI Student'" src="../../assets/ket-paper/student-play.gif" />
            <img class="student-stop" v-else src="../../assets/ket-paper/ai-student.png" /> -->
          	<img class="ai-student" src="../../assets/ket-paper/ai-student.png" />
          	<div class="ai-play" v-if="imgstatus == 'AI Student'">
          		<img src="../../assets/ket-paper/play.gif" />
          	</div>
          </div>
        </div>
        <div class="cont" v-if="pageData.roles?.indexOf('Student')!=-1&&stateType!='done'">
          <div class="name">Student</div>
          <div class="audio-cont">
            <img class="student-play" @click="stopVoice" v-if="imgstatus == 'Student'" src="../../assets/ket-paper/student-play.gif" />
            <img class="student-stop" v-else src="../../assets/ket-paper/student-stop.png" />
          </div>
        </div>
		<div class="finish" v-if="stateType=='done'">
			<div class="tit">Your Score</div>
		  <div class="score score1">{{paperData.speaking_score}}</div>
			<div class="tit">Total Score</div>
			<div class="score score2">150</div>
			<div class="tit tit3">Result</div>
			<div class="score score3">{{paperData.speaking_std_level}}</div>
		</div>
		<img class="baogao" @click="checkTit" v-if="stateType=='done'" src="../../assets/ket-paper/baogao.png" alt="" />
      </div>
    </div>
    <div class="modal" id="toggleStartContainer" v-if="!toggleStart&&stateType!='done'">
<!--      <img class="start" src="../../assets/hunan-paper/start.png" /> -->
		<audio-test v-if="testType == 0" @changeTestType="changeTestType" />
		<mic-test v-if="testType == 1" @changeTestType="changeTestType"/>
    </div>
    <audio ref="audioPlayer" @ended="handleAudioEnded">
      <source src="" type="audio/mp3">
    </audio>
	<audio ref="audioPlayer2" @ended="handleAudioEnded2">
	  <source src="" type="audio/mp3">
	</audio>
	<div class="modal modals" id="ScantronLoading" v-if="ScantronLoading">
	  <img style="width:60%" :src="uiStyleCfg?.scoringTitleImg||require('../../assets/ScantronLoading1.png')" />
	  <img class="" :src="uiStyleCfg?.scoringLogoImg||require('../../assets/ScantronLoading2.gif')" />
	</div>
	<el-dialog
	    title="题目详解"
	    :visible.sync="robotVisible"
	    custom-class="robot-window"
	    @close="closeRobot"
		v-dialogDrag
	    width="50%">
	  <div class="robot-wrap">
	    <div class="img-cont">
	      <img class="play" v-if="!isRobotPlay" @click="robotPlay()" :src="uiStyleCfg?.jxPlayImg" alt="">
	      <img class="pause" v-else @click="robotPause()" :src="uiStyleCfg?.jxPauseImg" alt="">
	      <img class="stop" @click="closeRobot()" :src="uiStyleCfg?.jxStopImg" alt="">
	      <img class="robot-img" :src="uiStyleCfg?.robotImg" />
	    </div>
	    <div class="robot-text" :style="{backgroundImg:uiStyleCfg?.areaBgImg}">
	      <div class="btn-group">
	        <div v-if="robotText" @click="tabClick(1)" class="tab-btn" :style="{fontSize:uiStyleCfg?.areaFontSize,backgroundColor:actTab == 1?uiStyleCfg?.jxTabBgColor1:uiStyleCfg?.jxTabBgColor2,color:actTab == 1?uiStyleCfg?.areaFontColor1:uiStyleCfg?.areaFontColor2}">内容</div>
	        <div v-if="voiceExplain" @click="tabClick(2)" class="tab-btn" :style="{fontSize:uiStyleCfg?.areaFontSize,backgroundColor:actTab == 2?uiStyleCfg?.jxTabBgColor1:uiStyleCfg?.jxTabBgColor2,color:actTab == 1?uiStyleCfg?.areaFontColor1:uiStyleCfg?.areaFontColor2}">发音</div>
	        <div class="score-btn" :style="{backgroundColor:uiStyleCfg?.scoreBgColor,color:uiStyleCfg?.scoreFontColor}">得分（满分<span :style="{color:uiStyleCfg?.totalFontColor}"> {{maxScore}} </span>）:<span :style="{color:uiStyleCfg?.realFontColor}"> {{myScore}} </span>分</div>
	      </div>
	      <textarea spellcheck="false" id="explainBox" class="my-textarea" style="position:relative;left:0%;top:0%;width:100%;height:100%;word-break: keep-all" :style="{fontSize:uiStyleCfg?.areaFontSize,color:uiStyleCfg?.areaFontColor}">
	        {{actTab==1?robotText:voiceExplain}}
	      </textarea>
	    </div>
	  </div>
	</el-dialog>
	<el-dialog
	  title="教程"
	  :visible.sync="dialogVisible"
	  custom-class="upload-card"
	  @close="dialogVisible = false"
	  width="940px"
	>
		<video v-if="dialogVisible" style="width:900px" src="https://static.bluebirdabc.com/lesson/material/nopid/1111/0ef59b682758f34114c503d5f263d1e71d96de79.mp4" autoplay controls></video>
	</el-dialog>
	<div v-if="hasHistory()"
	    style="position: fixed; left: 3%; bottom: 10%; width:50px; height:50px;
	    background-color: #0072b2dd; border-radius: 300px;
	    justify-content: center; justify-items: center; display: flex; cursor:pointer;
	     box-shadow: 0 2px 4px rgba(0, 0, 0, .6), 0 0 6px rgba(0, 0, 0, .4)"
	     onclick="history.back()"
	>
	  <div style="margin: auto; font-weight: bolder; color: #ffcc00dd;">返回</div>
	</div>
  </div>
</template>

<script>

// 提交代码备注测试
import {enterKetOralTest, getQuestions, signForm} from "../../api/index";
import '../../utils/HZRecorder';
import AudioTest from "../components/audioTest.vue";
import MicTest from "../components/micTest.vue";
export default {
  components: {AudioTest,MicTest},
  props: ["res"],
  data() {
    return {
      demoPng: require(`../../assets/ket-paper/demo.png`),
      pageData: {},
      imgstatus: "",
      toggleStart: false,
      audioContext: null,
      analyser: null,
      scriptProcessor: null,
      silenceStart: null,
      mediaStream: null,
      answerFlag: 0, // 新增变量，用于记录answer_flag的值
      hasSound: false, // 新增变量，用于记录是否有声音检测到
	  examinee_id:0,
	  paperData: {
	    total_score: "",
	  },
	  ScantronLoading: false,
	  robotVisible:false,
	  actTab:1,
	  robotVisible:false,
	  maxScore:0,
	  myScore:0,
	  robotText:"",
	  robotVoice:"",
	  voiceExplain:"",
	  voiceExplainAudioUrl:"",
	  isRobotPlay:false,
	  isPause:false,
		group_tag:"",
		testType:0,
		dialogVisible:true
    };
  },
  computed: {},
  created() {
    this.paperList();
    this.enterKetOralTest();
  },
  async mounted() {
    // Microphone
    let thiz = this;
    if (!thiz.audioRecorder) {
      await new Promise(resolve => {
        let config = { sampleBits: 16, sampleRate: 16000, format: 'wav' };
        window.HZRecorder.get((recorder) => {
          thiz.audioRecorder = recorder;
          resolve();
        }, config);
      });
    }
    this.pageData.imgUrl = "https://static.bluebirdabc.com/exam/a2_ket_oral_res/paper 01.png";
  },
  destroyed() {
    if (this.audioRecorder && this.audioRecording) {
      this.audioRecorder.stop();
      this.audioRecording = false;
      clearInterval(this.interval);
    }
    if (this.audioContext) {
      this.audioContext.close();
    }
  },
  methods: {
		hasHistory() {
		  return history.length > 1;
		},
		changeTestType(t){
			this.testType = t
			if(t==2){
				this.startTest()
			}
		},
	  async checkTit(){
		  const res = await getQuestions({
			user_id: Number(this.$route.query.user_id), //用户id, //用户id
			pid: this.$route.query.pid, //合作方id
			paper_id: this.$route.query.paper_id,
			examinee_id: this.examinee_id,
			type_code: 'speak', //待获取的题型代码
		  });
	    this.robotVisible = true;
	    this.maxScore = res.data.questions[0].score;
	    this.myScore = res.data.questions[0].my_score;
	    this.robotText = res.data.questions[0].explain;
	    this.robotVoice = res.data.questions[0].explainAudioUrl;
	    this.voiceExplain = res.data.questions[0].voice_explain;
	    this.voiceExplainAudioUrl = res.data.questions[0].voice_explainAudioUrl;
	    this.isRobotPlay = true;
	    this.isPause = false;
	    if(res.data.questions[0].voice_explainAudioUrl){
	      this.actTab = 2;
	      this.playAudio(res.data.questions[0].voice_explainAudioUrl);
	    }else{
	      this.actTab = 1;
	      this.playAudio(res.data.questions[0].explainAudioUrl);
	    }
	  },
    robotPlay(){
      this.isRobotPlay = true;
      if(this.isPause){
        const player = this.$refs.audioPlayer;
        player.play()
      }else{
        if(this.actTab == 2){
          this.playAudio(this.voiceExplainAudioUrl);
        }else{
          this.playAudio(this.robotVoice);
        }
      }
    },
    robotPause(){
      this.isRobotPlay = false;
      this.isPause = true;
      const player = this.$refs.audioPlayer;
      if (player) {
        player.pause();  // 暂停音频
      }
    },
    closeRobot(){
      this.isRobotPlay = false;
      this.isPause = false;
      const player = this.$refs.audioPlayer;
      if (player) {
        player.pause();  // 暂停音频
        player.currentTime = 0;  // 将音频的当前时间设置为0
      }
    },
	paperList(need) {
	    const res = this.res;
	    if (res.code == 0) {
	      console.log(res.data.group_tag);
		  this.stateType = res.data.status;
			const match = res.data.group_tag.match(/\d+/);
			this.group_tag = match ? parseInt(match[0], 10) : 1;
		  this.paperData.speaking_score = res.data.special.speaking_score;
			this.paperData.speaking_std_level = res.data.special.speaking_std_level;
	      this.examinee_id = res.data.examinee_id;
				if(this.$route.query.examinee_id == 0){
					this.updateExamineeId(res.data.examinee_id)
				}
		  this.uiStyleCfg = JSON.parse(this.res.data.ui_style_cfg.paper_cfg);
	    } else {
	      this.$message.error("试卷获取失败 "+(res.msg||''));
	    }
	  },
		updateExamineeId(newExamineeId) {
			const currentRoute = this.$route;

			// 如果 examinee_id 没有变化，不做任何处理
			if (currentRoute.query.examinee_id === newExamineeId) {
				return;
			}

			// 更新查询参数并强制页面刷新
			this.$router.push({
				name: currentRoute.name,
				params: currentRoute.params, // 保持路由参数不变
				query: {
					...currentRoute.query,
					examinee_id: newExamineeId // 更新 examinee_id 查询参数
				}
			}).then(() => {
				// 成功导航后，强制刷新页面
				window.location.reload();
			}).catch(err => {
				// 捕获 NavigationDuplicated 错误，但不处理它
				if (err.name !== 'NavigationDuplicated') {
					console.error(err);
				}
			});
		},
    startTest() {
      this.toggleStart = true;
      if (this.pageData.audioUrl1) {
        this.playAudio(this.pageData.audioUrl1);
        this.imgstatus = this.pageData.activeRole;
      }
    },
    playAudio(audio) {
	  console.log("audio",audio)
      this.$refs.audioPlayer.src = audio;
      const player = this.$refs.audioPlayer;
      if (player) {
        player.play().catch(error => console.error("Audio play failed", error));
      }
    },
	playAudio2(audio) {
	  console.log("audio2",audio)
	  this.$refs.audioPlayer2.src = audio;
	  const player = this.$refs.audioPlayer2;
	  if (player) {
	    player.play().catch(error => console.error("Audio play failed2", error));
	  }
	},
    handleAudioEnded() {
	  if(this.stateType=='done'){
		  return;
	  }
      this.imgstatus = "";
      console.log("Audio ended. Handling events...");
      if (this.pageData.isNeedRecording) {
        this.imgstatus = "Student";
        this.startVoice();
      } else {
        this.continueKetOralTest("");
      }
    },
	handleAudioEnded2() {
	  console.log("Audio2 ended. Handling events...");
	},
    async startVoice() {
      if (this.audioRecorder) {
        let time = this.pageData.totalSeconds;
        let waitingSeconds = this.pageData.waitingSeconds;
        let audioUrl2 = this.pageData.audioUrl2;
		if(audioUrl2){
			await this.setupAudioDetection();
		}

        this.interval = setInterval(() => {
          if (time > 0) {
            time--;
          } else {
            this.stopVoice();
          }
        }, 1000);

        this.audioRecorder.start();
        this.audioRecording = true;
        this.silenceStart = Date.now();
        this.hasSound = false; // 初始化为false，在开始录音时重置
      }
    },
    stopVoice() {
      if (this.audioRecorder && this.audioRecording) {
        this.audioRecorder.stop();
        this.audioRecording = false;
        console.log("录音停止");
        clearInterval(this.interval);
				const player = this.$refs.audioPlayer2;
				if (player) {
				  player.pause();  // 暂停音频
				  player.currentTime = 0;  // 将音频的当前时间设置为0
				}
        let blob = this.audioRecorder.getWavBlob();
        let file = new window.File([blob], "record.wav");

        // 根据检测到的声音设置answerFlag
		if (!this.hasSound) {
		  this.answerFlag = 0; // 没有声音录入
		  console.dir("没答")
		}
		else if(this.answerFlag == 2){
			console.dir("超时答的")
		}
		else if (Date.now() - this.silenceStart <= this.pageData.waitingSeconds * 1000) {
		  this.answerFlag = 1; // waitingSeconds时间内有声音录入
		  console.dir("答了")
		}
        this.continueKetOralTest(file);
      }
    },
	async setupAudioDetection() {
	  this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
	  this.analyser = this.audioContext.createAnalyser();
	  this.scriptProcessor = this.audioContext.createScriptProcessor(256, 1, 1);

	  if (!this.mediaStream) {
		this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
	  }

	  const source = this.audioContext.createMediaStreamSource(this.mediaStream);
	  source.connect(this.analyser);
	  this.analyser.connect(this.scriptProcessor);
	  this.scriptProcessor.connect(this.audioContext.destination);

	  this.scriptProcessor.onaudioprocess = () => {
		const array = new Uint8Array(this.analyser.frequencyBinCount);
		this.analyser.getByteFrequencyData(array);
		const volume = array.reduce((a, b) => a + b) / array.length;

		// 只在录音的时候检测音量
		if (this.audioRecording) {
		  if (volume > 20) {
			this.hasSound = true; // 检测到有声音
			this.silenceStart = Date.now();
		  } else if (Date.now() - this.silenceStart > this.pageData.waitingSeconds * 1000) {
            this.answerFlag = 2; // 设置answerFlag为2，表示waitingSeconds时间后有声音录入
			this.playAudio2(this.pageData.audioUrl2);
			// 释放音频处理器
			this.releaseAudioDetection();
		  }
		}
	  };
	},
	releaseAudioDetection() {
	  this.silenceStart = Date.now();
	  if (this.scriptProcessor) {
	    this.scriptProcessor.disconnect();
	    this.scriptProcessor.onaudioprocess = null;
	    this.scriptProcessor = null; // 确保释放资源
	  }
	  if (this.analyser) {
	    this.analyser.disconnect();
	    this.analyser = null;
	  }
	  if (this.audioContext) {
	    this.audioContext.close();
	    this.audioContext = null;
	  }
	},
    async enterKetOralTest() {
      const res = await enterKetOralTest({
        user_id: Number(this.$route.query.user_id),
        pid: this.$route.query.pid,
        paper_id: this.$route.query.paper_id,
        examinee_id: this.examinee_id,
        question_id: 324
      });
      console.dir(res);
      this.pageData = res.data;
    },
    async continueKetOralTest(audioFile) {
	  this.imgstatus = this.pageData.nextActiveRole;
      console.dir(audioFile);
      const formData = new FormData();
      signForm(formData);
      formData.append("examinee_id", this.examinee_id);
      formData.append("paper_id", this.$route.query.paper_id);
      formData.append("question_id", 324);
      formData.append("step", this.pageData.step);
      formData.append("answer_flag", this.answerFlag); // 添加answer_flag参数

      if (audioFile) {
        formData.append("my_answer", audioFile, "record.wav");
      } else {
        formData.append("my_answer", audioFile);
      }

      // 延迟delaySecs时间再发送请求
      await new Promise(resolve => setTimeout(resolve, this.pageData.delaySecs * 1000));

      fetch('https://readapi.bluebirdabc.com/exam/en/continueKetOralTest', {
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(res => {
				if (res.data.step == 'close-oral-test') {
					this.commitPaper();
					return;
				}
        this.pageData = res.data;
        this.pageData.imgUrl = this.pageData.imgUrl.replace(/\s/g, '%20');
        console.dir(this.pageData.imgUrl);
        this.$forceUpdate();
        this.startTest();
      })
      .catch(error => {
        console.error('失败:', error);
      });
    },
	async commitPaper() {
	  this.pageData.roles = ""
	  this.ScantronLoading = true;
	  const formData = new FormData();
    signForm(formData);
	  formData.append("examinee_id", this.examinee_id);
	  formData.append("paper_id", this.$route.query.paper_id);
	  formData.append("question_id", 324);
	  formData.append("step", 'close-oral-test');
	  formData.append("answer_flag", this.answerFlag); // 添加answer_flag参数
	  formData.append("my_answer", "");

	  // 延迟delaySecs时间再发送请求
	  await new Promise(resolve => setTimeout(resolve, this.pageData.delaySecs * 1000));

	  fetch('https://readapi.bluebirdabc.com/exam/en/continueKetOralTest', {
	    method: 'POST',
	    body: formData
	  })
	  .then(response => response.json())
	  .then(res => {
		  this.ScantronLoading = false;
		  window.location.reload();
	  })
	  .catch(error => {
	    console.error('失败:', error);
	  });
	},
  },
};
</script>




<style lang="less" scoped>
.ket-wrap{
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 5px;
	box-sizing: border-box;
	overflow: auto;
}
.top{
	display: flex;
	.logo-num{
		height: 14vh;
		background-color: #436887;
		margin-right: 15px;
		display: flex;
		align-items: center;
		img{
			width: 500px;
		}
	}
	.tit-cont{
		flex: 1;
		background-color: #525252;
		display: flex;
		align-items: center;
		font-size: 60px;
		font-weight: 900;
	}
	.title{
		color: #fff;
		padding-left: 180px;
		padding-right: 80px;
		border-right: 10px solid #fff;
	}
	.part{
		color: #FFC000;
		padding-left: 150px;
	}
}
.center{
	flex: 1;
	display: flex;
	margin-top: 15px;
	.pngCont{
		flex:1;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	.qa{
		width: 20%;
		margin-left: 15px;
		display: flex;
		flex-direction: column;
		.cont{
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		.name{
			color: #fff;
			font-size: 40px;
			font-weight: bold;
			background-color: #436887;
			text-align: center;
			padding: 1.5vh 0;
			margin-bottom: 8px;
			margin-top: 20px;
		}
		.examiner{
			width: 100%;
			height: 100%;
			background-image: url('../../assets/ket-paper/teacher.png');
			background-size: cover;
			background-position: center;
		}
		.audio-cont{
			flex: 1;
			background-color: #525252;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			.play-voice{
				width: 100%;
				height: 5vh;
				overflow: hidden;
				text-align: center;
				position: absolute;
				bottom: 0;
				background-color: #525252;
				img{
					width: 80%;
					height: 10vh;
					margin-top: -2vh;
				}
			}
			.student-play{
				height: 13vh;
				cursor: pointer;
			}
			.student-stop{
				height: 13vh;
			}
			.ai-student{
				height: 13vh;
			}
			.ai-play{
				height: 12vh;
				overflow: hidden;
				text-align: center;
				img{
					width: 80%;
					height: 400px;
					margin-top: -130px;
				}
			}
			.ai-cont{
				height: 13vh;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
  .modal{
    position: absolute;
    z-index: 10;
    transform: translateZ(10px);
    width: 100%;
    height: 100%;
    background-color: rgba(115, 149, 221, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .start{
      position: absolute;
      top:10%;
      cursor: pointer;
    }
  }
  .modals{
    background-color: rgba(0, 0, 0, .3);
  }
  .finish{
	  // flex: 1;
		overflow: auto;
		background-color: #478639;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 30px;
		overflow: auto;
		margin-bottom: 30px;
		padding-bottom: 30px;
		height: 80%;
		.tit{
			font-size: 65px;
			font-weight: 500;
			color: #fff;
			margin-top: 40px;
			margin-bottom: 20px;
		}
		.tit3{
			color: #ffff54;
			margin-top: 70px;
		}
	  .score{
		  font-size: 80px;
		  font-weight: 900;
		  color: rgb(46, 108, 58);
	  }
		.score1{
			background: #FFF;
			border-radius: 10px;
			padding: 15px;
			width: 80%;
			text-align: center;
		}
		.score2{
			background: #FFF;
			border-radius: 10px;
			padding: 15px;
			width: 80%;
			text-align: center;
		}
		.score3{
			background: #ffff54;
			border-radius: 10px;
			padding: 15px;
			width: 80%;
			text-align: center;
		}
  }
  .baogao{
	width:98%;
	cursor: pointer;
  }
  .robot-wrap{
    display: flex;
    align-items: flex-end;
    .img-cont{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right:20px;
    }
    .play,.stop,.pause{
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .robot-img{
      width:182px;
      height:258px;
      @media screen and (min-width: 0px) and (max-width: 540px) {
        width:10vw;
        height:14vw;
        margin-right:3vw;
      }
    }
    .robot-text0{
      background-image: url('../../assets/hunan-paper/area.png');
      width:1113px;
      height:308px;
      padding: 33px 35px 50px 35px;
      box-sizing: border-box;
      background-size: 100% 100%;
    }
    .robot-text{
      background-image: url('../../assets/hunan-paper/area2.png');
      width:1108px;
      height:624px;
      padding: 33px 35px 50px 35px;
      box-sizing: border-box;
      position: relative;
      background-size: 100% 100%;
      @media screen and (min-width: 0px) and (max-width: 540px) {
        flex:1;
        height: 50vw;
        padding: 5vw 5vw 7vw 5vw;
      }
      .btn-group{
        display: flex;
        position: absolute;
        top: -40px;
        width: calc(100% - 45px);
        .tab-btn{
          width: 120px;
          height: 40px;
          border-radius: 5px 5px 0 0;
          margin-left: 5px;
          background-color: #AFABAB;
          color: #fff;
          font-weight: bold;
          font-size:18px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .act-tab{
          background-color: #93CD70;
        }
        .score-btn{
          height: 30px;
          border-radius: 5px 5px 0 0;
          margin-left: auto;
          margin-right: 10px;
          background-color: #FFB929;
          color: #fff;
          font-size:20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          padding: 5px 20px;
          span{
            margin: 3px 10px 0;
          }
        }
      }
    }
  }
  ::v-deep .el-dialog{
	  border-radius:8px;
  }
</style>
